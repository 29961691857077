* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
}

img {
  max-width: 100%;
  height: auto;
}

.main {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x:hidden;
  background-image: url('../images/shared/bubble-gradient.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  position: relative;

  @media screen and (min-width: 410px) {
    width: 414px;
    margin: 0 auto;
  }

  &--drinks {
    @include breakpoint-min(md) {
      width: 600px;
    }
  }
}

.orientation {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  display: none;
  background-color: #434343;
  z-index: 1000;
  text-align: center;
  padding-top: 40vh;
}

.cookie__policy {
  position: fixed;
  width: 100%;
  height: auto;
  background-color: #025672;
  display: none;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  font-size: 13px;
  font-weight: 400;
  padding: 12px 12px 7px 12px;
  text-align: center;
  align-items: center;
  justify-content: center;
}


.cookie__policy p{
      width: 95vw;
    padding-right: 8px;
    font-size: 13px;
}

.cookie__policy button{
      -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #0099cc;
    border: 0;
    border-radius: 5px;
    font-weight: 700;
    padding: 10px 0px 10px 0px;
    cursor: pointer;
    transition: 250ms ease-in-out;
    color: #fff;
    display: block;
    text-align: center;
    width: 50px;
}

.cookie__policy a{
  color: #d8d8d8;
  text-decoration: underline;
}

.underline{
  text-decoration: underline;
}