
.drinks-page {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  transition: 250ms linear;
  width: 100%;
  margin-bottom:4vh;

  &__purchase-again,
  &__form {

    &.hidden {
      transition: 250ms linear;
      transform: translateX(100vw);
    }
  }
}

.drinks-section {
  width: 70%;
  margin: 15px auto 0;

  &--details {
    width: 80%;
  }

  @include breakpoint-min(md) {
    margin-top: 50px;
  }

  @include breakpoint-ipad-portrait {
    margin-top: 60px;
  }

  &__option {
    background: $color-white;
    border-radius: 10px;
    height: 45px;
    position: relative;
    margin-bottom: 2.2vh;

    @include breakpoint-min(xs) {
      height: 60px;
    }

    @include breakpoint-min(md) {
      margin-bottom: 30px;
      height: 75px;
    }

    &:last-of-type {
      margin-bottom: 2.5vh;
    }

    @include breakpoint-ipad-portrait {
      height: 80px;
      margin-bottom: 40px;

      &:last-of-type {
        margin-bottom: 60px;
      }
    }

    @include breakpoint-ipad-pro {
      height: 110px;
      margin-bottom: 5vh;
    }

  }

  &__image {
    width: 8vh;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    left: 0;

    &--tango {
      left: -10%;
      top: 40%;
    }

    &--lipton {
      left: -10%;
      width: 8vh;
    }

    &--pepsi {
      left: -8%;
      top: 48%;
      width: 18vh;
    }
  }

  &__checkbox {
    appearance: none;
    width: 25px;
    height: 25px;
    border: 2px solid $color-primary;
    border-radius: 4px;
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    right: 10%;
    cursor: pointer;
    outline: none;

    @include breakpoint-min(xs) {
      top: 50%;
    }

    &:checked {
      background: $color-primary;
      box-shadow: inset 0 0 0 3px $color-white;
    }
  }

  &__other {
    color: $color-primary;
    font-size: 20px;
    width: 90%;
    padding-top: 10px;
    padding-left: 30px;
    text-shadow: 1px 1px rgba($color-black, .3);

    @include breakpoint-min(xs) {
      font-size: 22px;
      padding-top: 17px;
    }

    @include breakpoint-min(md) {
      font-size: 28px;
      padding-top: 26px;
    }

    @include breakpoint-min(lg) {
      padding-top: 23px;
    }

    @include breakpoint-ipad-pro {
      padding-top: 40px;
    }
  }
}
